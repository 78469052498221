import '../styles/index.scss';
import 'babel-polyfill';
import $ from "jquery";
import popper from "popper.js";
import bootstrap from "bootstrap";

import animations from './animations.js';
import initParallaxes from './parallax.js';

animations();
initParallaxes();

function setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

if(!getCookie('privacy')) {
    $('#cookies').show();
} else {
    $('#cookies').remove();
}

function acceptCookies(e) {
    e.preventDefault();
    setCookie('privacy', new Date().toString());
    $('#cookies').remove()
}

const videoModal = $('#videoModal');
videoModal.on('show.bs.modal', function() {
    $(this).find('iframe')
        .attr('src', 'https://www.youtube.com/embed/jH90G4W6YCs?autoplay=1');
});

videoModal.on('hide.bs.modal', function() {
    $(this).find('iframe')
        .attr('src', '');
});

$(document).on('click touchend', '#cookies .cookie-ok', acceptCookies);

$(document).ready(function() {
    const video = $('#elearn-video video').get(0);
    video.play();
});

import ScrollMagic from "scrollmagic";
import { TweenMax, TimelineMax, Circ, Expo, Sine, Power2, Power1, Power0 } from "gsap"; // Also works with TweenLite and TimelineLite
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import "scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators";
import ImageSwitcher from "./image-switcher.js";
import * as lottie from "lottie-web";
import $ from "jquery";

window.$ = window.jQuery = require('jquery');
require('owl.carousel');

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

let controller = new ScrollMagic.Controller();

function initRevealOnScroll() {

    $('.stagger').each(function(i, item) {
        let children = $(item).children();
        console.log('stagger child', children);

        if(!isNaN(parseInt(children[0].dataset.index))) {
            children = children.sort((a, b) => (parseInt(a.dataset.index) > parseInt(b.dataset.index)) ? 1 : -1);
        }

        console.log(children);

        let stagger = new ScrollMagic.Scene({
            triggerElement: item,
            offset: 50
        }).addTo(controller)
        .setTween(TweenMax.staggerFromTo(children, 0.5, { opacity: 0, y: 100}, { y: 0, opacity: 1 }, 0.2))
        ;
    });

    $('.reveal-on-scroll:not(.image-switcher)').each(function(i, item) {
        let offset = parseInt($(item).data('offset') || 50);
        new ScrollMagic.Scene({
                    triggerElement: item,
                    triggerHook: 0.9,
                    offset: offset
            })
            .setTween(TweenMax.fromTo(item, 1, { opacity: 0, y:30 }, { opacity: 1, y: 0, ease: Power2.easeOut }))
            // .addIndicators({name: 'reveal on scroll'})
            .addTo(controller);
    });

    $('.appear-on-scroll:not(.image-switcher)').each(function(i, item) {
        let offset = parseInt($(item).data('offset') || 50);
        let distance = parseInt($(item).data('distance') || 100);

        new ScrollMagic.Scene({
                    triggerElement: item,
                    triggerHook: 0.9,
                    offset: offset,
                    duration: '100%'
            })
            .setTween(TweenMax.to(item, 1, { y:distance, ease: Power0.easeNone }))
            // .addIndicators()
            .addTo(controller);
    });

    $('.image-switcher').each(function(i, item) {
        let switcher = new ImageSwitcher($(item));

        let offset = parseInt($(item).data('offset') || 50);

        new ScrollMagic.Scene({
                triggerElement: item,
                triggerHook: 0.9,
                offset: offset
        })
        // .addIndicators({name: 'reveal on scroll'})
        // .setClassToggle(item, 'revealed')
        .setTween(TweenMax.fromTo(item, 1, { opacity: 0, y:30 }, { opacity: 1, y: 0, ease: Power2.easeOut }))
        .on('enter leave', function(e) {
            if(e.type === 'enter') {
                switcher.start();
            } else {
                switcher.stop();
            }
        })
        .addTo(controller);
    });

}

function initLottie() {
    let windowWidth = $(window).width();

    let animationContainer = $('#header-animation');
    let playButton = $('#fas-play-button');

    let animation = null;
    if(windowWidth > 578) {
        animation = require('./header.json');
    } else {
        animation = require('./header_mobile.json');
    }

    lottie.setQuality('low');
    lottie.loadAnimation({
        container: animationContainer[0],
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: animation
    });

    lottie.loadAnimation({
        container: playButton[0],
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: require('./play-button.json')
    });
}

function initELearnAnimation() {
    let dca = $('#elearn-dca-award');
    let marcin = $('#elearn-marcin');
    let karolina = $('#elearn-karolina');
    let elearnVideo = $('#elearn-video');

    let timeline = new TimelineMax();

    timeline
        .fromTo(dca, 0.7, { opacity: 0, y:120 }, { opacity: 1, y: 0, ease: Power2.easeIn }, 'position')
        .fromTo(marcin, 0.7, { opacity: 0, y: -180 }, { opacity: 1, y:0, ease: Power2.easeOut }, 'position+=0.7')
        .fromTo(karolina, 0.7, { opacity: 0, y: -180 }, { opacity: 1, y:0, ease: Power2.easeOut }, 'position+=0.7');

    let scene = new ScrollMagic.Scene({
        triggerElement: '#figure-animation',
        triggerHook: 0.7
    })
        // .addIndicators({name: 'moja animacja!!'})
        .setTween(timeline)
        .addTo(controller);
}

function StickyHeader() {

    let achievTitle = $('.section-last-years .section-title')
    let achievPin = new ScrollMagic.Scene({
        triggerElement: achievTitle[0],
        triggerHook: 0,
        offset: -20,
    })
    .setClassToggle(achievTitle[0], 'pinned')
    .setPin(achievTitle[0])
    .addTo(controller);

    let hideAchievPin = new ScrollMagic.Scene({
        triggerElement: '.footer',
        triggerHook: 0.5
    })
    .setTween(TweenMax.to(achievTitle[0], 0.5, { opacity: 0, ease: Power2.easeOut }))
    .addTo(controller);
}

function Pins() {

    let mix = new ScrollMagic.Scene({
        triggerElement: '.section-banking .section-title',
        triggerHook: 0.5,
        duration: '100%'
    })
    .setTween(TweenMax.fromTo('.mix-award', 1, { top: -150 }, { top: 0, ease: Power0.easeNone }))
    // .addIndicators({name: 'mix'})
    .addTo(controller)
    ;

    let golden = new ScrollMagic.Scene({
        triggerElement: '.section-banking .section-title',
        triggerHook: 0.5,
        duration: '100%'
    })
    .setTween(TweenMax.fromTo('.golden-arrow', 1, { top: -260 }, { top: 0, ease: Power0.easeNone }))
    // .addIndicators({name: 'golden'})
    .addTo(controller)
    ;

    let icono1 = new ScrollMagic.Scene({
        triggerElement: '#icono-1',
        triggerHook: 0.00,
        duration: '50%'
    })
        // .addIndicators({name: 'icono-1'})
        .addTo(controller)
        .setPin('#icono-1');

    let stagger = new ScrollMagic.Scene({
        triggerElement: '.iconos',
        offset: 50
    }).addTo(controller)
    .setTween(TweenMax.staggerFromTo(['.icono-3', '.icono-2', '.icono-5', '.icono-4'], 0.5, { opacity: 0, y: 100}, { y: 0, opacity: 1 }, 0.2))
    ;

    let fasIcon = new ScrollMagic.Scene({
        triggerElement: '#fas-logo',
        triggerHook: 0.0,
        duration: '50%'
    })
        .addTo(controller)
        .setPin('#fas-logo');

    // let effieBanner = new ScrollMagic.Scene({
    //     triggerElement: '.section-effie .section-banner',
    //     triggerHook: 0.2,
    //     duration: 74
    // }).addTo(controller).setPin('.section-effie .section-banner');

    this.enable = function() {
        let windowWidth = $(window).width();
        if(windowWidth > 578) {
            mix.enabled(true);
            golden.enabled(true);
            icono1.enabled(true);
            stagger.enabled(true);
            if(windowWidth >= 992) {
                this.enableFasIcon();
            }
        }
    };

    this.disable = function() {
        mix.enabled(false);
        golden.enabled(false);
        icono1.enabled(false);
        stagger.enabled(false);
        this.disableFasIcon();
    };

    this.enableFasIcon = function() {
        fasIcon.setPin('#fas-logo');
        fasIcon.enabled(true);
    };

    this.disableFasIcon = function() {
        fasIcon.removePin(true);
        fasIcon.enabled(false);
    };
}

function initOwl() {
    let carousel = $('.achievment-1 .achievment-images');
    if($(window).width() <= 576) {

        if(!carousel.hasClass('owl-loaded')) {
            carousel.addClass('owl-carousel');
            carousel.owlCarousel({
                items: 1.25,
                loop: true,
                center: false,
                margin: 30,
                autoWidth: false
            });
        }
    } else {
        carousel.owlCarousel('destroy');
        carousel.removeClass('owl-carousel');
    }
}

function animations() {
    initRevealOnScroll();
    initLottie();
    initOwl();
    new StickyHeader();
    initELearnAnimation();

    let pins = null;
    let windowWidth = $(window).width();
    if(windowWidth > 578) {
        pins = new Pins();
        pins.enable();
    }


    $(window).resize(function() {

        let windowWidth = $(window).width();
        if(windowWidth > 578) {
            if(!pins) {
                pins = new Pins();
            }
            pins.enable();
            if(windowWidth < 992) {
                pins.disableFasIcon();
            }
        } else if(pins) {
            pins.disable();
        }

        initOwl();
    });
}

export default animations;

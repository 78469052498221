
import ScrollMagic from "scrollmagic";
import { TweenMax, TimelineMax, Power2, Power1 } from "gsap"; // Also works with TweenLite and TimelineLite
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import "scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators";
import $ from "jquery";

function setParallaxDimensions(container) {
    let width = container.width();
    container.css('height', width + 'px');

    return width;
}

function initParallaxes() {
    let leoParallaxScene = null;
    let fasParallaxScene = null;
    $(window).resize(function(e) {
        if(leoParallaxScene) {
            leoParallaxScene.duration(setParallaxDimensions($('#leo-stars')));
        }

        if(fasParallaxScene) {
            fasParallaxScene.duration(setParallaxDimensions($('#fas-banner')));
        }
    });


    setParallaxDimensions($('#leo-stars'));
    // setParallaxDimensions($('#fas-banner'));
    leoParallaxScene = leoParallax();
    // fasParallaxScene = fasParallax();
}

function leoParallax() {

    let controller = new ScrollMagic.Controller();
    let leoStars = $('#leo-stars');

    let duration = $(window).height() + leoStars.height();

    let slideParallax = new ScrollMagic.Scene({
            triggerElement: '#leo-parallax',
            triggerHook: 1,
            duration: duration
        })
        // .addIndicators({name: 'parallax'})
        .setTween(TweenMax.fromTo('.bcg', 2, { top: -300 }, { top: 0, ease: Power1.easeOut }))
        .addTo(controller);


    return slideParallax;
}

function fasParallax() {
    let controller = new ScrollMagic.Controller();
    let leoStars = $('#fas-banner');

    let duration = $(window).height() + leoStars.height();

    let slideParallax = new ScrollMagic.Scene({
        triggerElement: '.section-fas',
        triggerHook: 1,
        duration: duration
    })
    // .addIndicators({name: 'parallax'})
        .setTween(TweenMax.fromTo('.bcg', 2, { top: -300 }, { top: 0, ease: Power1.easeOut }))
        .addTo(controller);


    return slideParallax;
}

export default initParallaxes;
